import React from 'react';
import DocumentMeta from 'react-document-meta';
import '../../styles/app.css';
import '../../styles/metafans.scss';

import Vip from '../sections/Home/Vip.jsx';
import Genesis from '../sections/Home/Genesis.jsx';
import How from '../sections/Home/How.jsx';
import Mint from '../sections/Home/Mint.jsx';
import Meet from '../sections/Home/Meet.jsx';
import CountdownTimer from '../sections/Home/Countdown.jsx';
import Newbs from '../sections/Home/Newbs.jsx';
import Signup from '../sections/Home/Signup.jsx';
import Faqs from '../sections/Home/FAQs.jsx';
import FaqBg from '../../assets/images/faqbg.jpeg';
import bghtw from '../../assets/images/bghtw1.jpg';
import image from '../../assets/images/image.png';
import HowToWinCard from '../cards/HowToWinCard.jsx';
// import BgBottom from '../../assets/images/poker_table.jpg';



function Home() {
  const cardData = [
    {
      title: 'MetaFans NFL 2024-2025 Schedule',
      items: ['Weekly Square Pools for Prime-Time Games (TH, SUN, MON)', '$500 Prize Pool Per Game', 'NFL Playoffs & Superbowl square pools', 'See MetaFans Levels for How to Qualify <a href="/new-member-benefits">here</a>', 'Holders must join discord <a href="https://discord.gg/bWrRubrnNF" target="_blank">here</a>  &amp; verify Wallet holdings'],
      flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
    },
    {
      title: 'MetaFans CFB 2024-2025 Schedule',
      items: ['Weekly Square Pools for Saturday prime time college football', '$500 Prize Pool Per Game', 'MetaFans College National Championship Playoff Challenge', 'See MetaFans Levels for How to Qualify <a href="/new-member-benefits">here</a>', 'Holders must join discord <a href="https://discord.gg/bWrRubrnNF" target="_blank">here</a>  &amp; verify Wallet holdings'],
      flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
    },
    // {
    //   title: 'MetaFans PGA GOLF',
    //   items: ['Weekly Squares for PGA golf tournaments', 'See MetaFans Levels for Qualification Status', 'Holders must join discord <a href="https://discord.com/invite/bWrRubrnNF">here</a>  &amp; verify Wallet holdings'],
    //   flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
    // },
    {
      title: 'Coming Soon in 2025',
      items: ['NCAA Basketball March Madness Bracket Challenge for RMB', '$10,000 prize pool', 'MetaFans PGA Major Golf Tournament Challenge for RMB', '$2,500 prize pool per Major Golf Tournament'],
      flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
    },
    {
      title: 'Coming Soon in 2025',
      items: ['MetaFans NBA Playoffs for RMB', '$5,000 prize pool', 'MetaFans NHL Playoffs for RMB', '$5,000 NHL Prize pool'],
      flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
    },
  ];
 
  const meta = {
    title: 'MetaFans NFT Collection',
    description:
      'Learn about the ultimate sports-focused NFT project with the best utility!',
  };

  return (
    <>
      <DocumentMeta {...meta}></DocumentMeta>
      <div className="theme-dk-blue" style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        {/* <CountdownTimer /> */}
        <Vip />
        <Genesis /> 
        <div className="flex items-center justify-center bg-gray-900 " style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="grid w-full grid-cols-1 gap-4 m-5 md:m-10 md:grid-cols-2">
            {cardData.map((card, index) => (
              <HowToWinCard key={index} title={card.title} items={card.items} flipItems={card.flipItems} />
            ))}
            {/* <div className="relative w-full shadow-lg htw-card flip-card">
              <div class="flip-card-inner">
                <div className={`first-view p-4 md:p-10 flip-card-front`}>
                  <h2 class="mb-2 text-4xl font-semibold text-center text-white">Coming Soon</h2>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <Mint />
        <Meet />
        {/* <Faqs /> */}
        {/* <Signup /> */}
        {/* <img src={BgBottom} className="w-full h-auto p-20 bg-center bg-no-repeat bg-contain" alt="bg-bottom" /> */}
      </div>
    </>
  );
}

export default Home;
