import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryActionButton from '../PrimaryActionButton/PrimaryActionButton';
import {
  FaDiscord,
  FaTwitter,
  FaInstagram,
  FaTiktok,
  FaTwitch,
} from 'react-icons/fa';

import opensea from '../../assets/images/opensea.svg';

const Footer = () => {
  return (
    <section className="footer bg-dark1">
      <div className="grid grid-cols-1 lg:grid-cols-3 footer-container">
        <div className="order-2 col-span-2 lg:order-1">
          <div className="flex flex-col items-center gap-5 mb-0 text-sm font-semibold footer-nav lg:flex-row text-grey">
            <a href="/">Sports</a>
            <a href="/poker-club">Poker Club</a>
            <a href="/about-metafans">About MetaFans</a>
            <a href="/new-member-benefits">New Member Benefits</a>
            {/* <a href="/fun-with-drew">Fun With BetOnDrew</a> */}
            <a href="/nft-utilities">NFT & Utilities</a>
            <a href="/faq">FAQ</a>
            {/* <a href="https://ticket.metafans.com/">Tickets</a> */}
           
          </div>
        </div>
        <div className="order-1 col-span-1 lg:order-2">
          <div className="flex flex-col lg:flex-row lg:justify-end">
            <div className="flex flex-row items-center justify-center order-2 gap-5 mt-5 mb-5 mr-0 footer-icons lg:justify-start lg:order-1 lg:mt-0 lg:mr-3 lg:mb-0">
              <div>
                <a
                  href="https://discord.gg/bWrRubrnNF"
                  target="_blank"
                  className="discord"
                >
                  <FaDiscord />
                </a>
              </div>
              <div>
                <a
                  href="https://twitter.com/metafansnft"
                  target="_blank"
                  className="twitter"
                >
                  <FaTwitter />
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/metafansnft/"
                  target="_blank"
                  className="insta"
                >
                  <FaInstagram />
                </a>
              </div>
              <div>
                <a
                  href="hhttps://www.twitch.tv/metafansnft"
                  target="_blank"
                  className="twitch"
                >
                  <FaTwitch />
                </a>
              </div>
              <div>
                <a
                  href="https://www.tiktok.com/@metafans"
                  target="_blank"
                  className="tiktok"
                >
                  <FaTiktok />
                </a>
              </div>
              <div>
                <a
                  href="https://opensea.io/collection/metafans-collection"
                  target="_blank"
                  className="opensea"
                >
                  <img src={opensea} alt="opensea icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between mt-2 footer-copyright">
        <div>
          <p className="mb-0 text-xs font-bold text-grey">© 2024 MetaFans</p>
        </div>
        <div className="flex items-center justify-end mini-nav">
          <div>
            <Link to="/privacy-policy">
              <p className="mb-0 text-xs font-bold text-grey">Privacy policy</p>
            </Link>
          </div>
          <div>
            <Link to="/terms">
              <p className="mb-0 text-xs font-bold text-grey">
                Terms of services
              </p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
