import React, { Component } from 'react';
import discord from '../../../../assets/images/discord-blue.svg';
import bannerOne from '../../../../assets/images/banner-change1.png';

class ClockTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  // Second Timer
  componentDidMount() {
    setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
  }
  leading1(num) {
    return num < 10 ? '0' + num : num;
  }
  getTimeUntil(deadlineTwo) {
    const time = Date.parse(deadlineTwo) - Date.parse(new Date());
    let days,
      hours,
      minutes,
      seconds = 0;
    if (time > 0) {
      days = Math.floor(time / (1000 * 60 * 60 * 24));
      hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      minutes = Math.floor((time / 1000 / 60) % 60);
      seconds = Math.floor((time / 1000) % 60);
    }
    this.setState({ days, hours, minutes, seconds });
  }
  render() {
    return (
      <section className="countdown" id="something-big">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row align-start countdown-container">
            <div className="w-full lg:w-5/12 xl:w-1/2">
              <img
                className="secret"
                src={bannerOne}
                alt="metafans clubhouse countdown"
              ></img>
            </div>
            <div className="w-full lg:w-7/12 xl:w-1/2 pl-5 pr-5 mt-10 lg:mt-0 countdown-info ml-5">
              <h1 className="lt-white font-bold">
                Clubhouse is coming. Get ready to enhance your sports
                experience.
              </h1>
              <p className="text-dk-grey font-bold info">
                We have launched the first game and you don’t want to miss the
                first Clubhouse game! Join today!
              </p>
              {/* Phase 2 */}
              <div className="launch-countdown count-two lt-white p-3">
                <div className="text-center flex justify-center items-center mt-5">
                  <h2 className="mr-5">Clubhouse Launch</h2>
                  <p className="launch-date accent-green">
                    August 23, 2022 at 12PM EST
                  </p>
                </div>
                <div className="flex justify-center items-center countdown-meter pl-5 pr-5 pt-3 text-center">
                  <div className="flex justify-center items-center countdown-meter pl-5 pr-5 pt-3 text-center">
                    {' '}
                    <div className="Clock-hours">
                      <h1 className="font-medium">
                        {this.leading1(this.state.days)}
                      </h1>
                      <p>days</p>
                    </div>
                    <div className="Clock-hours">
                      <h1 className="font-medium">
                        {this.leading1(this.state.hours)}
                      </h1>
                      <p>hours</p>
                    </div>
                    <div className="Clock-minutes">
                      <h1 className="font-medium">
                        {this.leading1(this.state.minutes)}
                      </h1>
                      <p>minutes</p>
                    </div>
                    <div className="Clock-seconds">
                      <h1 className="font-medium">
                        {this.leading1(this.state.seconds)}
                      </h1>
                      <p>seconds</p>
                    </div>
                  </div>
                </div>
                <div className="p-3 pt-4 text-center footer-count-notes">
                  <p className="text-white font-bold mb-5">
                    See what the hype is all about
                  </p>
                  <a
                    href="https://discord.com/invite/bWrRubrnNF"
                    target="_blank"
                    className="discord flex justify-center items-center"
                  >
                    <img src={discord} alt="join our discord"></img>
                    <p>Join our discord community</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ClockTwo;
