import React from 'react';
import DocumentMeta from 'react-document-meta';
import '../../styles/app.css';
import '../../styles/metafans.scss';
import bghtw from '../../assets/images/bghtw1.jpg';

function QualifyWin() {

    const meta = {
        title: 'New Member Benefits | MetaFans NFT',
        description:
            'Committed to bringing people together, providing unforgettable IRL experiences. Learn about the MetaFans and our supported charities',
    };
    return (
        <>
            <DocumentMeta {...meta}></DocumentMeta>
            <div className="theme-dk-blue" style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container px-4 py-4 mx-auto my-8 text-white md:px-16 md:py-16 bg-gray-900/50" style={{ borderRadius: '20px', border: '1px solid #ffffff1c' }}>
                    <h1 className='mt-5 mb-16 text-3xl font-bold tracking-tight text-center lt-white md:text-5xl'>
                        NEW SPORTS & POKER CLUB MEMBER BENEFITS
                    </h1>
                    {/* <p className='mt-4 mb-4 text-md md:text-lg text-white/80'>
                        New Holders and even Alumni Holders can now qualify for even more exciting chances to win. This is something that no NFT Community has anywhere in the metaverse. Now you can win with MetaFans Weekly Sports Challenges, Weekly Poker Games & even receive a sport pick every weekend through the Year!
                    </p> */}
                    <p className='mt-4 mb-4 text-md md:text-lg text-white/80'><strong>New Member Benefits</strong><span > have arrived for </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><span > NFT Holders! </span><strong>Here's your chance to elevate your </strong><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><strong> experience!</strong><span >&nbsp;</span><strong>Starting September 1st, 2024</strong><span >, dedicated collectors can unlock more exclusive benefits and </span><span >qualify for even more exciting chances to win!</span></p>
                    
                    <p className='mt-4 mb-4 text-md md:text-lg text-white/80'><span >Now you can </span><strong>Qualify for the </strong><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><strong> Pro Package </strong><span >for </span><strong>More Weekly Sports Challenges</strong> <strong>&amp; Weekly Poker Games!</strong><span >&nbsp; </span><strong>AND</strong><span >, for you</span> <span >Sports Gamblers,</span><strong> Qualify for the NEW </strong><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><strong> Sports Plus Package, </strong><span >which entitles you to the</span><strong> &ldquo;Sports Pick of the Week&rdquo; every Sunday for the NFL, NBA or MLB for all 52 weeks throughout the Year!</strong></p>

                    <h3 className='mb-5 text-xl font-bold tracking-tight text-white/80 md:text-2xl'>MetaFans Pro Package:</h3>
                    <p className='mt-4 mb-4 text-md md:text-lg text-white/80'><span >Anyone who Mints 3 </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><span > NFTs on or after September 1, 2024, will receive a Free NFT to complete a FULL SET of all 4 Characters in the </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><span > Genesis Collection and will trade Characters at no cost to Complete your 1</span><span >st</span><span > Full Set. MF Pros receive these Benefits: </span></p>

                    <ul className='list-disc list-inside text-md md:text-lg text-white/80'>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg> <span>Buyin for $5.50 NLH PKO &ldquo;</span><a target="_blank" className='underline' href="https://www.twitch.tv/betondrew"><span>BetOnDrew</span></a><span>&rdquo; Home Game - Wednesdays at 8 pm EST</span></li>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg> <p><span >Buyin for $16.50 NLH PKO </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><span > Open Home Game &ndash; Sundays at 7 pm EST </span></p></li>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg> <p><span >1 square for </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><span > NFL Pro &amp; NCAA College Primetime Game Square Pools</span></p></li>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>1 bracket for the NCAA Basketball March Madness Challenge</li>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>1 team entry PGA Major Golf challenge</li>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>1 entry NBA & NHL Playoff challenges</li>
                    </ul>

                    <h3 className='mt-8 mb-5 text-xl font-bold tracking-tight text-white/80 md:text-2xl'>MetaFans Sports Plus Package: </h3>
                    <h3 className='mt-8 mb-5 text-lg font-bold tracking-tight text-white/80 md:text-xl'>Qualifying Criteria: </h3>
                    <ul className='list-disc list-inside text-md md:text-lg text-white/80'>
                        <li className="flex items-center text-md md:text-lg text-white/80">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg> <p><strong>Option 1:</strong><span >&nbsp;Mint 4 new </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><span > NFTs, with at least 1 Ranked in the Top 100 (1%), 250 (2.5%), Top 500 (5%), or Top 1000 (10%) of the official Rarity Rankings.</span></p></li>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg> <p><strong>Option 2:</strong><span>&nbsp;Mint 4 new </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span>MetaFans</span></a><span> NFTs and Purchase 1 NFT in the </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span>MetaFans</span></a><span> Geneses Collection on the Open Market (Opensea.io, direct holder purchase, etc.), if one of your Minted NFT does not meet the Rarity Ranking Requirement.&nbsp; The additional NFT must meet the Ranking Requirements at the time of acquisition.</span></p></li>
                    </ul>
                    <h3 className='mt-8 mb-5 text-lg font-bold tracking-tight text-white/80 md:text-xl'>Sports Plus Package Benefits: </h3>
                    <ul className='list-disc list-inside text-md md:text-lg text-white/80'>
                        <li className="flex items-center mb-4 text-md md:text-lg text-white/80">
                             <p><strong>All </strong><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span>MetaFans</span></a><strong> Pro Package Benefits:</strong></p>
                        </li>
                        <ul className='list-disc list-inside text-md md:text-lg text-white/80'>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> <span>Buyin for $5.50 NLH PKO &ldquo;</span><a target="_blank" className='underline' href="https://www.twitch.tv/betondrew"><span>BetOnDrew</span></a><span>&rdquo; Home Game - Wednesdays at 8 pm EST</span></li>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> <p><span >Buyin for $16.50 NLH PKO </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><span > Open Home Game &ndash; Sundays at 7 pm EST </span></p></li>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> <p><span >1 square for </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span >MetaFans</span></a><span > NFL Pro &amp; NCAA College Primetime Game Square Pools</span></p></li>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>1 bracket for the NCAA Basketball March Madness Challenge</li>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>1 team entry PGA Major Golf challenge</li>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>1 entry NBA & NHL Playoff challenges</li>
                        </ul>
                        
                        <li className="flex items-center mt-4 mb-4 text-md md:text-lg"><p><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span>MetaFans</span></a><strong> "Sports Pick of the Week":</strong> Receive a weekly sports betting pick (against the point spread) for 52 weeks. The value of your pick increases based on the rarity of your highest-ranked NFT:</p></li>

                        <ul className='list-disc list-inside text-md md:text-lg text-white/80'>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> Top 1,000: $25</li>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> Top 500: $50</li>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> Top 250: $75</li>
                            <li className="flex items-center text-md md:text-lg">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> Top 100: $100</li>
                           
                        </ul>
                    </ul>
                    <h3 className='mt-8 mb-5 text-xl font-bold tracking-tight text-white/80 md:text-2xl'>How to Participate: </h3>
                    <ul className='list-disc list-inside text-md md:text-lg text-white/80'>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg> CryptoDaddio will provide 4 games from the NFL, NBA or MLB games each week.</li>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg> Choose 1 of the Sunday games provided.</li>
                        <li className="flex items-center text-md md:text-lg">
                            <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg> <p><span>Create a ticket on the official </span><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span>MetaFans</span></a> <a target="_blank" className='underline' href="https://discord.gg/bWrRubrnNF"><span>Discord</span></a><span> server before the game starts.</span></p></li>
                    </ul>

                    <p className='mt-8 mb-5 font-bold tracking-tight text-md text-white/80 md:text-lg'><strong>By strategically building your </strong><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span>MetaFans</span></a><strong> collection, you can unlock valuable benefits and elevate your sports betting experience. There will be more special benefits the more you collect &amp; weekly giveaways to help everyone build the biggest and best </strong><a target="_blank" className='underline' href="https://opensea.io/collection/metafans-collection"><span>MetaFans</span></a><strong> Genesis NFT Collection for you!</strong></p>
                
                    {/* <h3 className='mb-5 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        MetaFans Alumni Real Money Benefits:
                    </h3>
                    <p className='mt-4 mb-4 text-md md:text-lg text-white/80'>
                        Holders of a SET of MetaFans NFTs will continue to enjoy both our Sports and Poker Club Real Money
                        Benefits (“RMB”) including A) an entry for the MetaFans BetonDrew Weekly $5.50 No Limit Hold’em PKO
                        Home Game on Wednesdays and B) 1 square pool pick for each of the weekly MetaFans NFL Primetime
                        Game Contests.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        Level 1: MetaFans Prospect
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-md md:text-lg text-white/80'>
                        Join our Exclusive Poker Club for $99/mo. & receive entries for the MetaFans RMB BetonDrew Weekly $5.50 NLH PKO Home Game PLUS the NEW Sunday $16.50 MetaFans Open NLH PKO Game  (“ALL METAFANS RMB WEEKLY TOURNEYS”) and B) 1 square pool pick for each of the weekly MetaFans NFL Primetime Game Contests.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        Level 2: MetaFans Rookie
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-md md:text-lg text-white/80'>
                        Anyone who mints 1 MetaFans NFT will get A) Entries for 3 months for ALL METAFANS RMB WEEKLY
                        TOURNEYS and B) 1 square pool pick for each of the weekly MetaFans NFL Primetime Game Contests.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        Level 3: MetaFans Pro
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-md md:text-lg text-white/80'>
                        Anyone who owns 1 MetaFans NFT & Mints 2 More, will receive a Free MetaFans NFT to complete their SET
                        of 4 MetFans from CryptoDaddio’s Wallet & receive A) Entries for 6 Months for ALL METAFANS RMB
                        WEEKLY TOURNEYS and B) 3 square pool picks for each of our weekly NFL and COLLEGE Football
                        Primetime Games.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        Level 4: MetaFans Champion
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-md md:text-lg text-white/80'>
                        Anyone who “Favors the Bold” and Mints 4 New MetaFans, will receive A) Entries to ALL METAFANS RMB
                        WEEKLY TOURNEYS and B) 5 square pool picks for all our weekly NFL & College Football Primetime Games.
                        DO NOT WORRY MetaFans, if you Mint a Duplicate Character, CryptoDaddio will complete your SET of 4 at
                        no cost to you!
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        Level 5: MetaFans MVP – MetaFans NFT Owners can become an MVP on 2 different levels:
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-md md:text-lg text-white/80'>
                        MVP-1: MetaFans Alumni who own at least of 3 MetaFans 4 Character Sets and who has Minted 2
                        New MetaFans will receive A) Entries to ALL METAFANS RMB WEEKLY TOURNEYS and B) 3 square
                        pool picks for each of our weekly NFL & College Football Primetime Games.
                    </p>
                    <p className='mt-4 mb-4 mb-5 text-md md:text-lg text-white/80'>
                        MVP-2: Alumni and MF Pros who have Minted 4 New MetaFans and who own a total of at least 3
                        MetaFans 4 Character Sets will receive A) Entries to ALL METAFANS RMB WEEKLY TOURNEYS and
                        B) 5 square pool picks for each of our weekly NFL & College Football Primetime Games.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        Level 6: MetaFans Hall of Famer - MetaFans NFT Owners can become Hall of Famers on 2
                        levels:
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-md md:text-lg text-white/80'>
                        HOF-1 Any MetaFans Owner who Mints a MetaFans NFT  Ranked in the Top75 in RARITY and, who has also Minted at least 4 New MetaFans on or after the September 1, 2024, Launch Date, will receive A) All the RMB Utilities of MVP-2 Level and B) an all-expense paid trip for 2 to Ceasar’s Palace in Las Vegas including 2 tickets to a Vegas professional sporting event, concert or show (RMB Prize for Level 6 HOF-1 limited to first three (3) Minted in Top 75 in Rarity & verified).
                    </p>
                    <p className='mt-4 mb-4 mb-5 text-md md:text-lg text-white/80'>
                        HOF-2 Any MetaFans Owner who Mints one of the remaining 7 OBG MetaFans NFT Characters will receive  A) All the RMB Utilities  of MVP-2 Level B) an all-expense paid trip for 2 to Ceasar’s Palace in Las Vegas including 2 Caesar’s Suite Tickets to a Vegas professional sporting event, concert or show as special guests of CryptoDaddio and CryptoMammio (RMB Prize for Level 6 HOF-1 limited to first three (3) Minted in Top 75 in Rarity & verified)!
                    </p> */}
                </div>
            </div>
        </>
    );
}

export default QualifyWin;
