import React from 'react';
import DocumentMeta from 'react-document-meta';
import tickets from '../../assets/images/ticket-gradient.png';
import flag from '../../assets/images/flag-gradient.png';
import mic from '../../assets/images/mic-gradient.png';
import characters from '../../assets/images/charactersmarching.svg';

function OptIn() {
  const meta = {
    title: 'MetaFans Newsletter Opt-in',
    description:
      'Connect with our ultimate sports community and secure additional discounts and utility on our Discord platform!',
  };
  return (
    <>
      <DocumentMeta {...meta}></DocumentMeta>
      <section className="relative overflow-hidden py-44 opt2">
        <img
          src={characters}
          className="absolute z-0 right-48 top-5 xl:top-10 w-60 h-60 rotate-12"
        />
        <div className="px-5 mx-auto mt-20 text-center xl:px-0 xl:mt-0 xl:w-1/3">
          <h1 className="mb-2 font-bold leading-none text-white">
            Enjoy the Perks of Being a Holder
          </h1>
          <p className="mb-5 text-grey">
            Connect with our ultimate sports community and secure additional
            discounts and utility on our Discord!
          </p>
          <div className="mb-16 klaviyo-form-T24r2w"></div>
        </div>
        <div className="w-full px-20 mx-auto lg:px-0 lg:w-2/3">
          <div className="flex flex-col mt-5 font-medium lg:flex-row mint-list">
            <div className="flex items-start justify-center w-full lg:px-5 lg:w-1/3">
              <div>
                <img
                  className="w-[1.5rem] md:w-[1rem] lg:w-[2rem] xl:w-[3rem]"
                  src={tickets}
                  alt="steps to minting a metafan"
                ></img>
              </div>
              {/* <p className="ml-5 text-sm text-gray-300 md:text-md">
                Discount tickets to IRL events via our <a className="underline" href="https://ticket.metafans.com/" target="_blank">Ticket Portal</a>.
              </p> */}
            </div>
            <div className="flex items-start justify-center w-full my-3 border-white lg:border-y-1 lg:px-5 lg:my-0 lg:border-x-1 lg:border-y-0 lg:w-1/3 lg:mx-0">
              <div>
                <img
                  className="w-[1.75rem] md:w-[1rem] lg:w-[2rem] xl:w-[3rem]"
                  src={flag}
                  alt="steps to minting a metafan"
                ></img>
              </div>
              <p className="ml-5 text-sm text-gray-300 md:text-md">
                Additional utility, MetaFans-specific recognition, and discounts on our <a
                  className="mb-2 underline "
                  href="https://discord.com/invite/bWrRubrnNF"
                  target="_blank"
                >Discord!</a>.
              </p>
            </div>
            <div className="flex items-start justify-center w-full px-0 lg:px-5 lg:w-1/3">
              <div>
                <img
                  className="w-[2.5rem] md:w-[1.5rem] lg:w-[3rem] xl:w-[4rem]"
                  src={mic}
                  alt="steps to minting a metafan"
                ></img>
              </div>
              <p className="ml-5 text-sm text-gray-300 md:text-md">
                Chances to win tickets to your favorite concerts and sporting events.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OptIn;