import React from 'react';
import { useScript } from '../../../hooks/useScript';

import discord from '../../../assets/images/discord_whitebk.svg';
import twitter from '../../../assets/images/twitter_whitebk.svg';
import insta from '../../../assets/images/ig_whitebk.svg';
import email from '../../../assets/images/email_whitebk.svg';
import facebook from '../../../assets/images/fb_whitebk.svg';

const SignupTeam = () => {
  return (
    <section className="home-signup team" id="signup-form">
      <div className="text-center signup-container">
        <div className="text-content">
          <h1 className="mb-5 text-3xl font-bold text-white md:text-4xl">
            Start your journey now!
          </h1>
          <p className="mb-3 font-medium text-white">
            Connect with other people who love sports and get access to
            exclusive experiences right now!
          </p>
          <p className="font-medium text-white">
            Follow us on social and sign up for email/SMS notifications to stay
            informed about events and community activities. We'll send you a
            bi-weekly newsletter, announcements, and no spam ever!
          </p>
          <div className="flex items-center justify-center mt-5 social-icons">
            <div>
              <a
                href="https://discord.com/invite/bWrRubrnNF"
                target="_blank"
                className="discord"
              >
                <img src={discord} alt="discord link"></img>
              </a>
            </div>
            <div>
              <a
                href="https://twitter.com/metafansnft"
                target="_blank"
                className="twitt"
              >
                <img src={twitter} alt="twitter link"></img>
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/metafansnft/"
                target="_blank"
                className="ig"
              >
                <img src={insta} alt="instagram link"></img>
              </a>
            </div>
          </div>
        </div>
        <div className="signup-box pb-28">
          <div className="flex flex-col items-center justify-center text-left">
            <div className="w-full klaviyo-form-T24r2w" id="email-signup"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupTeam;
